.cursor-pointer {
  cursor: pointer;
}

:root {
  --color-dark-skyblue: #00a5ce;
  --color-light-skyblue: #e8f8fb;
}

.flex-1 {
  flex: 1;
}
.color-blue{
  color: #00a5ce;
}

.width-max_content {
  width: max-content !important;
}

.page-header {
  z-index: 9;
  min-height: 74px;
  position: sticky;
  top: 0;
  /* background-color: var(--color-light-skyblue); */
  z-index: 999;
}

.mh-38 {
  min-height: 38px;
  max-height: 38px;
}

.managealertspopup {
  max-height: 20vw;
  overflow: auto;
}

.dashboard_cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
}

.dashboard_cards .card {
  min-height: 8rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  transition: background-color 1s ease, box-shadow 1s ease, color 1s ease;
  border-radius: 0.5vw !important;
  border: 0;
}

.dashboard_cards .card:hover {
  /* transform: all 5s;
  color: var(--color-dark-skyblue);
  font-size: 18px;
  font-weight: bold; */
  box-shadow: rgba(0, 165, 206, 0.8) 0px 2px 8px 0px;
}

a {
  text-decoration: none !important;
}

.link-color {
  color: var(--color-dark-skyblue);
  text-decoration: underline !important;
}

.loading_main {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  z-index: 99999;
}

.loading_main .loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create_button,
.search_btn {
  background-color: var(--color-dark-skyblue) !important;
  color: white !important;
  border: 1px solid var(--color-dark-skyblue) !important;
  padding: 5px;
  border-radius: 8px;
  min-width: 100px;
  height: 38px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create_button:hover,
.search_btn:hover {
  background-color: var(--color-white) !important;
  color: var(--color-dark-skyblue) !important;
}

.reset_btn,
.back_btn {
  border: 1px solid black !important;
  color: black !important;
  background-color: white !important;
  padding: 7px 14px;
  border-radius: 8px;
  min-width: 100px;
  height: 38px;
}

.reset_btn {
  min-width: 45px !important;
}

.reset_btn:hover,
.back_btn:hover {
  color: white !important;
  background-color: black !important;
  min-width: 100px;
}

.table_header {
  height: 50px;
  background: #f6f6f8 !important;
  vertical-align: middle !important;
  top: 0;
  z-index: 9;
  outline: 1px solid #e3e6e8;
}
.table_header th {
  background: #f6f6f8 !important;
}



.table_header tr {
  border: 0 !important;
}
table tr {
  border: 1px solid #e3e6e8;
}

table td {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.activestatus,
.inactivestatus {
  width: 15px;
  height: 15px;
  display: block;
  border-radius: 50%;
}

.activestatus {
  background: green;
}

.inactivestatus {
  background: red;
}

.react-datepicker-wrapper {
  width: 100%;
}

.date_picker_calendar_icon {
  position: absolute;
  right: 0px;
  top: 5px;
}

.form {
  background-color: var(--color-light-skyblue);
  border-radius: 1.5rem;
}

.form-check-input:checked {
  background-color: var(--color-dark-skyblue) !important;
  border-color: var(--color-light-skyblue) !important;
}

.form-check-input:hover {
  cursor: pointer;
}

.form-check-input {
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.listselect:hover {
  background: rgba(0, 165, 206, 0.5) !important;
  color: var(--color-white) !important;
}

/* Toggle switch button*/
.switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 20px;
}

.switch input {
  opacity: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  /* The slider */
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  display: flex;
  align-items: center;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: var(--color-dark-skyblue);
}

input:checked+.slider:before {
  transform: translateX(calc(100% + 7px));
}

/* Toggle switch button*/

.ck-editor__editable {
  min-height: 200px;
}

.height_maxcontent {
  height: max-content;
}

.create_post .upload:nth-of-type(2) {
  display: flex;
}

.user_name_and_date {
  font-size: 14px;
  color: #747474;
}

/* custom scrollbar starts*/
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: var(--color-dark-skyblue);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px gray;
}

::-webkit-scrollbar {
  width: 5px;
}

.chats ::-webkit-scrollbar-track {
  box-shadow: none !important;
}

/* custom scrollbar ends */

.form-control:focus {
  border-color: var(--color-dark-skyblue) !important;
  box-shadow: 0 0 5px 1px var(--color-dark-skyblue) !important;
}

.input-readonly {
  background-color: #e9ecef !important;
  opacity: 1;
  pointer-events: none;
}

.chats_dashboard {
  background-color: white;
  border: 5px solid var(--color-dark-skyblue);
  border-radius: 5px;
  z-index: 99;
  box-shadow: 2px 2px 20px 3px black;
  border-radius: 10px;
  width: 65%;
  margin-left: auto;
  min-width: 900px;
}

.chat_block {
  position: fixed;
  right: 0;
  z-index: 9999;
  bottom: 25px;
  right: 30px;
}

.main_chat_icon :hover,
.main_chat_icon_close :hover {
  transform: scale(1.03);
  cursor: pointer;
}

.main_chat_icon svg:hover path {
  fill: var(--color-dark-skyblue);
}

.main_chat_icon_close {
  position: absolute;
  right: 0;
  top: 6px;
  right: 6px;
}

.custom_astrick:after {
  color: red;
  content: " *";
}

.slick-prev:before,
.slick-next:before {
  color: black !important;
}

.tab-btn.active-tab,
.tab-btn:hover {
  color: var(--color-white);
  background-color: var(--color-dark-skyblue) !important;
  text-align: center !important;
  border-radius: 5px;
}

.PhoneInputInput,
.PhoneInput--focus {
  border: none !important;
  outline: none !important;
  padding: 0 !important;
}

/** Pagination starts **/
.pagination button {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pagination .active,
.pagination .page-num:hover {
  border-radius: 50%;
  background-color: var(--color-dark-skyblue) !important;
  color: white;
}

.pagination button:disabled svg path {
  fill: #9d9fa0 !important;
}

/** Pagination ends **/


.react-select-prefix__control.react-select-prefix__control--is-focused {
  border-color: var(--color-dark-skyblue) !important;
  box-shadow: 0 0 3px 2px rgba(0, 165, 206, 0.5) !important;
  outline: none !important;
  border: 1px solid var(--color-dark-skyblue) !important;
}

.react-select-prefix__control {
  border-radius: 5px !important;
}

.form-control,
.react-select-prefix__control {
  border: 1px solid rgb(198, 198, 198) !important;
}

.page-title {
  font-size: var(--size-25px);
  font-family: var(--font-calbri-bold);
}

.search-bar {
  position: sticky;
  top: 75px;
  z-index: 998;
  background-color: var(--color-white);
}

.react-select-prefix__placeholder {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.form-layout{
  min-height: 600px;
}

@media screen and (min-width:1200px) and (max-width:1599px) {
  .pagination button {
    width: 30px;
    height: 30px;
  }
}

::-webkit-input-placeholder {
  font-family: var(--font-calbri-regular);
}

