.login-title
{
  font-size: var(--font-24px);
  font-family: var(--font-vfutura-heavy);
  color: var(--color-dark-skyblue);
}
.logo-width
{
  width: 18rem;
}
.height-20
{
  min-height: 1.5rem;
}
.register-form__fields
{
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.login-title {
  font-size:1.5rem;
  color: var(--color-dark-skyblue);
}

.form-button {
  background-color: var(--color-dark-skyblue) !important;
  color: white !important;
  border: 1px solid var(--color-dark-skyblue) !important;
  height: 38px;
  border-radius: 8px;
}

.eyeIcon {
  top: 0.45vw;
  right: 0.5vw;
}