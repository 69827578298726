.manage_employee_filter {
    display: grid;
    grid-template-columns: repeat(6, minmax(100px, 1fr));
    gap: 15px;
}

@media (max-width: 1200px) {
    .manage_employee_filter {
        grid-template-columns: repeat(2, minmax(100px, 1fr));
    }

    .manage_employee_filter>div:last-child {
        grid-column: 1 / -1;
    }
}

.profile_image {
    width: 120px;
    height: 120px;
}
