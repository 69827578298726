/* .tabs-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
} */

.tab-button {
  flex: 1;
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  cursor: pointer;
  text-align: center;
}

.tab-button:hover {
  background-color: var(--color-dark-skyblue);
  color: white;
}

.tab-button.active {
  border-color: var(--color-dark-skyblue);
}

.tab-content {
  width: 100%;
  background-color: var(--color-light-skyblue);
  border-radius: 1vw;
  padding: 1vw;
}



.navigation-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.thumbnail-image {
  width: 60px;
  height: 60px;
}

.post-image {
  height: 10vw;
}

.post-image-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.progress-bar {
  border: 1px solid rgb(0, 0, 0);
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-top: 10px;
}

.progress-bar-fill {
  background-color: #007bff;
  border-radius: 5px;
}


.listHeight {
  max-height: calc(100vh - 390px);
  overflow: auto;
}

.tab-content .d-flex.flex-row a,
.documentGeneral a {
  color: var(--color-dark-skyblue);
}

.tab-content .d-flex.flex-row a:hover,
.documentGeneral a:hover {
  text-decoration: underline !important;
}

.newsDescription {
  max-height: calc(100vh - 335px);
  overflow: auto;
}

.documentGeneral div:nth-child(3) {
  display: flex;
}

.documentGeneral div:nth-child(3)>div {
  margin-right: 20px;
}

.dashboard_cards {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 25px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .dashboard_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 25px;
    margin: 0 -5px;

  }

}