:root {
  --color-dark-skyblue: #00a5ce;
  --color-white:#fff;
  --size-25px: 25px;
  --size-16px:16px;
  --size-18px:18px;
  --font-calbri-regular: "calbri-regular";
  --font-calbri-bold: "calbri-bold";
}

@font-face {
  font-family: "calbri-regular";
  src: url("./static/fonts/calibri/calibri-regular.ttf");
}

@font-face {
  font-family: "calbri-bold";
  src: url("./static/fonts/calibri/calibri-bold.ttf");
}

body {
  font-family: var(--font-calbri-regular) !important;
}

.color-dark-skyblue {
  color: var(--color-dark-skyblue) !important;
  font-family: var(--size-25px);
  font-family: var(--font-calbri-bold);
}