/* .tabs-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
} */

.tab-button {
  flex: 1;
  padding: 10px 20px;
  margin: 0 5px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  cursor: pointer;
  border-radius: 7px;
  text-align: center;
}

.tab-button.active {
  background-color: var(--color-dark-skyblue);
  color: white;
  border-color: var(--color-dark-skyblue);
}

.tab-content {
  width: 100%;
  padding: 20px;
  background-color: var(--color-light-skyblue);
}

