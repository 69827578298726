.manage-users-action svg:hover{
  fill: var(--color-dark-skyblue);
  color: var(--color-dark-skyblue);
}

/* Table */

.TableHeight {
  max-height: calc(100vh - 327px);
  overflow: auto;
  padding-top: 1px;
}

.cameraIconPosition {
  bottom: 3px;
  right: 2px;
}

.createGroupTabs .tab-button:not(:last-child) {
  margin-right: 10px !important;
}

.createGroupTabs .tab-button {
  background-color: var(--color-white);
  color: var(--color-dark-skyblue);
  border: 1px solid var(--color-dark-skyblue);
}