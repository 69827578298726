@media screen and (min-width:0px) and (max-width:1023px) {

    table thead {
        display: none;
    }

    table tr {
        height: auto !important;
    }

    table tbody {
        display: block;
        width: 100%;
    }

    table,
    table tbody,
    table tr,
    table td {
        display: block;
        width: 100%;
        border: 0 !important;
    }

    table tbody tr {
        border: 0.052vw solid var(--color-primary) !important;
        border-radius: 0.5vw;
        margin-bottom: 2vw !important;
        padding: 1vw !important;
    }

    table td {
        text-align: right;
        padding-left: 53% !important;
        text-align: left !important;
        position: relative;
        word-break: break-word;
        min-height: 3rem;
    }

    table td::before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        width: 50%;
        text-align: left;
        color: var(--color-primary) !important;
    }

    .manage_border tr {
        border: 1px solid #b5b5b5 !important;
        margin-bottom: 1rem;
        box-shadow: rgb(118 118 118 / 24%) 0px 3px 8px;

    }

    .ipad-icons {
        padding: 0 !important;
    }

    .width-max_content {
        width: 75% !important;
        margin: auto !important;
    }

    .resposive-btn {

        font-family: var(--font-family-poppins-regular);
        font-size: var(--font-size-14px);
        background-color: var(--color-dark-skyblue) !important;
        color: white !important;
        border: 1px solid var(--color-dark-skyblue) !important;
        padding: 5px;
        border-radius: 8px;
        min-width: 100px;
        height: 38px;
        transition: all 0.3s ease;
    }
}