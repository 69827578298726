.main {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.username_block {
  background: #00a5ce4d;
  font-family: var(--font-calbri-bold);
  font-size: var(--size-18px);
}

.message_block {
  box-shadow: inset 0px 0px 7px 0px rgba(0, 165, 206, 0.3);
}
.usename {
  font-size: 2rem;
}

.users_names {
  border-radius: 2px;
}

.connected_user {
  font-size: var(--size-25px);
  color: var(--color-dark-skyblue);
  font-family: var(--font-calbri-bold);
}
.msg_time {
  font-size: 12px;
}

.own {
  text-align: right;
  background: #00a5ce4d;
  display: block;
  max-width: 65%;
  margin-left: auto;
  width: max-content;
}

.other {
  background: gray;
  display: block;
  max-width: 65%;
  margin-right: auto;
  width: max-content;
}

.msg_count {
  background: grey;
  color: white;
  border-radius: 30px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  font-size: 12px;
  min-width: 20px;
  min-height: 20px;
}

.msgdate {
  display: flex;
  font-size: 14px;
}
.msgdate:before,
.msgdate:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid;
  margin: auto;
}

.message-history {
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
}

.msgSend_btn:hover {
  cursor: pointer;
  transform: scale(1.1);
}
