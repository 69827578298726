.tabs-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.tab-button {
  flex: 1;
  background-color: #00a5ce;
  color: #fff !important;
  cursor: pointer;
  text-align: center;
  border: .052vw solid #00a5ce !important;
  border-radius: .5vw;
  box-shadow: 0 .104vw .104vw 0 #63636333;
  color: var(--primaryBrandColor) !important;
  flex-basis: 0;
  flex-grow: 1;
  font-size: var(--size-16px);
  min-width: 8vw;
  padding: .3vw;
  transition: color .3s ease, background-color .3s ease;
}


.tab-button:hover {
  background-color: var(--color-dark-skyblue);
  color: white;
}

.tab-button.active {
  background-color: var(--color-dark-skyblue);
  color: white !important;
  border-color: var(--color-dark-skyblue);
}

.Title-forms {
  font-size: 1.5rem;
  font-weight: 700;
}